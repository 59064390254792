define('ember-animated/-private/sprite', ['exports', 'ember-animated/-private/transform', 'ember-animated', 'ember-animated/-private/margin-collapse', 'ember-animated/-private/bounds'], function (exports, _transform, _emberAnimated, _marginCollapse, _bounds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COPIED_CSS_PROPERTIES = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var COPIED_CSS_PROPERTIES = exports.COPIED_CSS_PROPERTIES = ['opacity', 'font-size', 'color', 'background-color', 'letter-spacing'];

  var numericCSSProps = {
    animationIterationCount: true,
    columnCount: true,
    fillOpacity: true,
    flexGrow: true,
    flexShrink: true,
    fontWeight: true,
    lineHeight: true,
    opacity: true,
    order: true,
    orphans: true,
    widows: true,
    zIndex: true,
    zoom: true
  };

  var inFlight = new WeakMap();

  /**
    A Sprite is our handle to a DOM element that we want to animate.
  
    It manages locking and unlocking the element (which means taking it
    in and out of static document flow so it's readily animatable).
  
    It tracks the sprite's current transform.
  
    It tracks the sprite's initial and/or final bounds, as measured
    from the actual pre- and/or post-animation DOM.
  
    @class Sprite
  */

  var Sprite = function () {
    _createClass(Sprite, null, [{
      key: 'offsetParentStartingAt',
      value: function offsetParentStartingAt(element) {
        var parent = getEffectiveOffsetParent(element);
        if (!parent) {
          parent = document.getElementsByTagName('body')[0];
        }
        return new this(parent, true, null, null);
      }
    }, {
      key: 'offsetParentEndingAt',
      value: function offsetParentEndingAt(element) {
        var parent = getEffectiveOffsetParent(element);
        if (!parent) {
          parent = document.getElementsByTagName('body')[0];
        }
        return new this(parent, false, null, null);
      }
    }, {
      key: 'positionedStartingAt',
      value: function positionedStartingAt(element, offsetSprite) {
        if (!offsetSprite.initialBounds) {
          throw new Error("offset sprite must have initial bounds");
        }
        return new this(element, true, 'position', offsetSprite);
      }
    }, {
      key: 'positionedEndingAt',
      value: function positionedEndingAt(element, offsetSprite) {
        if (!offsetSprite.finalBounds) {
          throw new Error("offset sprite must have final bounds");
        }
        return new this(element, false, 'position', offsetSprite);
      }
    }, {
      key: 'sizedStartingAt',
      value: function sizedStartingAt(element) {
        return new this(element, true, 'size', null);
      }
    }, {
      key: 'sizedEndingAt',
      value: function sizedEndingAt(element) {
        var sprite = new this(element, false, 'size', null);
        sprite._initialBounds = _bounds.emptyBounds;
        return sprite;
      }
    }]);

    function Sprite(element, inInitialPosition, lockMode, offsetSprite) {
      _classCallCheck(this, Sprite);

      this.element = element;
      this.owner = null;
      this._transform = null;
      this._cumulativeTransform = null;
      this._offsetSprite = offsetSprite;

      // This gets set by TransitionContext when a sprite is used within
      // a TransitionContext. It's a convenience that allows users to
      // just pass Sprites to Motions without also passing the context.
      this._transitionContext = null;

      var predecessor = inFlight.get(element);
      if (predecessor && lockMode) {
        // When we finish, we want to be able to set the style back to
        // whatever it was before any Sprites starting locking things,
        // so inheriting the state from our predecessor is important for
        // correctness.
        this._styleCache = predecessor._styleCache;
        this._parentElement = predecessor._parentElement;
        this._revealed = predecessor._revealed;
        this._imposedStyle = predecessor._imposedStyle;
        this._collapsingChildren = predecessor._collapsingChildren;
        this._lockMode = predecessor._lockMode;
        if (lockMode !== predecessor._lockMode) {
          throw new Error('probable bug in ember-animated: can\'t change lock mode from ' + predecessor._lockMode + ' to ' + lockMode);
        }
      } else {
        this._styleCache = null;
        this._parentElement = null;
        this._revealed = null;
        this._imposedStyle = null;
        this._collapsingChildren = null;
        this._lockMode = lockMode;
        if (lockMode === 'position') {
          this._rememberPosition();
          this._cacheOriginalStyles();
        } else if (this._lockMode === 'size') {
          this._rememberSize();
          this._cacheOriginalStyles();
        }
      }

      this._lockedToInitialPosition = inInitialPosition;
      if (inInitialPosition) {
        this.measureInitialBounds();
        this._finalComputedStyle = null;
        this._finalBounds = null;
        this._originalFinalBounds = null;
        this._finalPosition = null;
        this._finalCumulativeTransform = null;
      } else {
        this._initialComputedStyle = null;
        this._initialBounds = null;
        this._originalInitialBounds = null;
        this._initialPosition = null;
        this._initialCumulativeTransform = null;
        this.measureFinalBounds();
      }

      if (Ember.testing) {
        Object.seal(this);
      }
    }

    /**
      A DOMRect representing the place where this sprite will start the
      transition. Not every sprite has initialBounds (a newly inserted
      sprite will not -- it will only have finalBounds).
       The position is measured *relative* to our offsetParent, if we
      have one. Most of the time we want motions to act in relative
      terms, so that if we're inside another animator things still work
      out correctly.
       You can manipulate initialBounds using methods like startAtPixel.
       Motions should look at initialBounds and finalBounds to decide
      what to do.
       @accessor initialBounds
      @type {DOMRect}
    */


    _createClass(Sprite, [{
      key: 'getInitialDimension',
      value: function getInitialDimension(name) {
        return this._initialPosition[name];
      }
    }, {
      key: 'getFinalDimension',
      value: function getFinalDimension(name) {
        return this._finalPosition[name];
      }
    }, {
      key: 'getCurrentBounds',
      value: function getCurrentBounds() {
        if (this._offsetSprite) {
          return (0, _bounds.relativeBounds)(this.element.getBoundingClientRect(), this._offsetSprite.getCurrentBounds());
        } else {
          return this.element.getBoundingClientRect();
        }
      }
    }, {
      key: '_getCurrentPosition',
      value: function _getCurrentPosition() {
        if (isSVG(this.element)) {
          var element = this.element;

          return {
            x: getSVGLength(element, 'x'),
            y: getSVGLength(element, 'y'),
            cx: getSVGLength(element, 'cx'),
            cy: getSVGLength(element, 'cy'),
            r: getSVGLength(element, 'r'),
            width: getSVGLength(element, 'width'),
            height: getSVGLength(element, 'height'),
            transform: element.getAttribute('transform')
          };
        } else {
          var style = this.element.style;
          return {
            top: style.top,
            left: style.left,
            bottom: style.bottom,
            right: style.right,
            transform: style.transform
          };
        }
      }
    }, {
      key: '_reapplyPosition',
      value: function _reapplyPosition(pos) {
        if (!pos) {
          return;
        }
        if (isSVG(this.element)) {
          var element = this.element;

          setSVGLength(element, 'x', pos);
          setSVGLength(element, 'y', pos);
          setSVGLength(element, 'cx', pos);
          setSVGLength(element, 'cy', pos);
          setSVGLength(element, 'r', pos);
          setSVGLength(element, 'width', pos);
          setSVGLength(element, 'height', pos);
          setAttribute(element, 'transform', pos);
        } else {
          var style = this.element.style;
          style.top = pos.top;
          style.left = pos.left;
          style.right = pos.right;
          style.bottom = pos.bottom;
          style.transform = pos.transform;
        }
      }
    }, {
      key: 'measureInitialBounds',
      value: function measureInitialBounds() {
        if (this._initialBounds) {
          throw new Error("Sprite already has initial bounds");
        }
        this._inInitialPosition = true;
        if (this._offsetSprite) {
          this._initialBounds = (0, _bounds.relativeBounds)(this.element.getBoundingClientRect(), this._offsetSprite.initialBounds);
        } else {
          this._initialBounds = this.element.getBoundingClientRect();
        }
        this._initialComputedStyle = copyComputedStyle(this.element);
        this._initialPosition = this._getCurrentPosition();
        this._originalInitialBounds = this._initialBounds;
        this._initialCumulativeTransform = (0, _transform.cumulativeTransform)(this.element);
      }
    }, {
      key: 'measureFinalBounds',
      value: function measureFinalBounds() {
        if (this._finalBounds) {
          throw new Error("Sprite already has final bounds");
        }
        this._inInitialPosition = false;
        if (this._offsetSprite) {
          this._finalBounds = (0, _bounds.relativeBounds)(this.element.getBoundingClientRect(), this._offsetSprite.finalBounds);
        } else {
          this._finalBounds = this.element.getBoundingClientRect();
        }
        this._finalComputedStyle = copyComputedStyle(this.element);
        this._finalPosition = this._getCurrentPosition();
        this._originalFinalBounds = this._finalBounds;
        this._finalCumulativeTransform = (0, _transform.cumulativeTransform)(this.element);
      }
    }, {
      key: 'difference',
      value: function difference(which, otherSprite, otherWhich) {
        var x = this[which].left;
        var y = this[which].top;
        if (this._offsetSprite) {
          x += this._offsetSprite[which].left;
          y += this._offsetSprite[which].top;
        }
        if (otherSprite._offsetSprite) {
          x -= otherSprite._offsetSprite[otherWhich].left;
          y -= otherSprite._offsetSprite[otherWhich].top;
        }
        return {
          dx: x - otherSprite[otherWhich].left,
          dy: y - otherSprite[otherWhich].top
        };
      }
    }, {
      key: '_rememberSize',
      value: function _rememberSize() {
        this._imposedStyle = {};

        if (isSVG(this.element)) {
          // we're not doing anything to lock the width & height
          // SVGElements. it seems rare that we'd need to, since svg
          // layout tends to be pretty literal.
          return;
        }

        // If the user has already provided an inline width or height,
        // they are taking the wheel and we have to trust them to do
        // something reasonable.
        //
        // I'm not using getComputedStyle here because its width and
        // height are fairly useless for our purposes (we want "computed"
        // values, but for backward compat with CSS 2.0, getComputedStyle
        // actually returns the "used" values for width and height).

        if (this.element.style.width === "") {
          this._imposedStyle.width = this.element.offsetWidth;
          // TODO: do a more sophisticated size measurement so we don't
          // need to impose border-box. If we're only imposing width OR
          // height and we weren't originally in border box, we can get an
          // incorrect change in the non-imposed dimension.
          this._imposedStyle['box-sizing'] = 'border-box';
        }
        if (this.element.style.height === "") {
          this._imposedStyle.height = this.element.offsetHeight;
          this._imposedStyle['box-sizing'] = 'border-box';
        }
      }
    }, {
      key: '_lazyOffsets',
      value: function _lazyOffsets(computedStyle) {
        var _this = this;

        var offsets = void 0;
        return function () {
          if (!offsets) {
            offsets = findOffsets(_this.element, computedStyle, _this.transform, _this._offsetSprite);
          }
          return offsets;
        };
      }
    }, {
      key: '_rememberPosition',
      value: function _rememberPosition() {
        var computedStyle = getComputedStyle(this.element);
        var style = this.element.style;
        var offsets = this._lazyOffsets(computedStyle);
        var tx = 0;
        var ty = 0;

        this._rememberSize();

        if (isSVG(this.element)) {
          // svg elements are effectively always already absolutely
          // positioned by their own coordinates.
          return;
        }

        if (computedStyle.position !== 'absolute' && computedStyle.position !== 'fixed') {
          this._imposedStyle.position = 'absolute';
        }

        if (style.top === "" && style.bottom === "") {
          // The user had no preexisting inline vertical positioning, so we take over.
          this._imposedStyle.top = offsets().top;
          this._imposedStyle.marginTop = 0;
        } else if (this._imposedStyle.position) {
          // the user has inline styles for controlling vertical position,
          // but the element was not absolutely positioned, so we apply an
          // offseting transform.
          ty = offsets().top - parseFloat(computedStyle.top);
        }

        if (style.left === "" && style.bottom === "") {
          // The user had no preexisting inline horizontal positioning, so we take over.
          this._imposedStyle.left = offsets().left;
          this._imposedStyle.marginLeft = 0;
        } else if (this._imposedStyle.position) {
          // the user has inline styles for controlling vertical position,
          // but the element was not absolutely positioned, so we apply an
          // offseting transform.
          tx = offsets().left - parseFloat(computedStyle.left);
        }
        if (tx || ty) {
          this._transform = this.transform.mult(new _transform.default(1, 0, 0, 1, tx, ty));
          this._imposedStyle.transform = this.transform.serialize();
        }

        this._collapsingChildren = (0, _marginCollapse.collapsedChildren)(this.element, computedStyle, 'Top');
      }
    }, {
      key: '_cacheOriginalStyles',
      value: function _cacheOriginalStyles() {
        var cache = {};
        var style = this.element.style;
        Object.keys(this._imposedStyle).forEach(function (property) {
          cache[property] = style[property];
        });
        this._styleCache = cache;
      }
    }, {
      key: 'lock',
      value: function lock() {
        // In case the user has caused our inline-style-driven position
        // to drift, we put it back.
        this._reapplyPosition(this._initialPosition);

        this.applyStyles(this._imposedStyle);
        this._handleMarginCollapse();
        inFlight.set(this.element, this);
        this._inInitialPosition = this._lockedToInitialPosition;
      }
    }, {
      key: 'unlock',
      value: function unlock() {
        (true && Ember.warn("Probable bug in ember-animated: an interrupted sprite tried to unlock itself", this.stillInFlight(), { id: "ember-animated-sprite-unlock" }));

        inFlight.delete(this.element);
        var style = this.element.style;
        var cache = this._styleCache;
        Object.keys(cache).forEach(function (property) {
          style[property] = cache[property];
        });

        // In case the user has caused our inline-style-driven position
        // to drift, we put it back.
        this._reapplyPosition(this._finalPosition);

        this._clearMarginCollapse();
      }
    }, {
      key: 'applyStyles',
      value: function applyStyles(styles) {
        var _this2 = this;

        if (!this._lockMode) {
          throw new Error("can't apply styles to non-lockable sprite");
        }
        if (styles !== this._imposedStyle) {
          Object.keys(styles).forEach(function (property) {
            if (_this2._imposedStyle[property] == null) {
              _this2._styleCache[property] = _this2.element.style[property];
            }
            _this2._imposedStyle[property] = styles[property];
          });
        }
        Object.keys(styles).forEach(function (property) {
          var camelizedProp = Ember.String.camelize(property);
          var val = styles[property];
          if (typeof val === 'number') {
            _this2.__element.style[camelizedProp] = numericCSSProps[camelizedProp] ? styles[property] : styles[property] + 'px';
          } else {
            _this2.__element.style[camelizedProp] = styles[property];
          }
        });
      }
    }, {
      key: 'stillInFlight',
      value: function stillInFlight() {
        return inFlight.get(this.element) === this;
      }
    }, {
      key: 'hide',
      value: function hide() {
        this._revealed = false;
        this.__element.classList.add('ember-animated-hidden');
      }
    }, {
      key: 'reveal',
      value: function reveal() {
        if (!this.revealed) {
          this._revealed = true;
          this.__element.classList.remove('ember-animated-hidden');
        }
      }
    }, {
      key: 'display',
      value: function display(flag) {
        if (flag) {
          this.__element.classList.remove('ember-animated-none');
        } else {
          this.__element.classList.add('ember-animated-none');
        }
      }
    }, {
      key: 'translate',
      value: function translate(dx, dy) {
        var t = this.transform;
        t = t.mult(new _transform.default(1, 0, 0, 1, dx / t.a, dy / t.d));
        this._transform = t;
        this.applyStyles({
          transform: t.serialize(),
          transformOrigin: '0 0'
        });
      }
    }, {
      key: 'scale',
      value: function scale(scaleX, scaleY) {
        var t = this.transform.mult(new _transform.default(scaleX, 0, 0, scaleY, 0, 0));
        this._transform = t;
        this.applyStyles({
          transform: t.serialize(),
          transformOrigin: '0 0'
        });
      }
    }, {
      key: 'rehome',
      value: function rehome(newOffsetSprite) {
        var screenBounds = this.absoluteInitialBounds;
        var newRelativeBounds = (0, _bounds.shiftedBounds)(screenBounds, -newOffsetSprite.initialBounds.left, -newOffsetSprite.initialBounds.top);

        var initialAmbientTransform = this._offsetSprite.cumulativeTransform;
        var finalAmbientTransform = newOffsetSprite.cumulativeTransform;

        var t = this.transform;
        t = t.mult(new _transform.default(initialAmbientTransform.a / finalAmbientTransform.a, 0, 0, initialAmbientTransform.d / finalAmbientTransform.d, (newRelativeBounds.left - t.tx) / t.a, (newRelativeBounds.top - t.ty) / t.d));
        this._transform = t;
        this._imposedStyle.transform = t.serialize();
        this._imposedStyle.transformOrigin = '0 0';
        this._imposedStyle.top = 0;
        this._imposedStyle.left = 0;
        this._offsetSprite = newOffsetSprite;
        this._initialBounds = newRelativeBounds;
        this._inInitialPosition = true;
      }
    }, {
      key: '_handleMarginCollapse',
      value: function _handleMarginCollapse() {
        if (this._collapsingChildren) {
          var children = this._collapsingChildren;
          for (var i = 0; i < children.length; i++) {
            children[i].classList.add('ember-animated-top-collapse');
          }
        }
      }
    }, {
      key: '_clearMarginCollapse',
      value: function _clearMarginCollapse() {
        if (this._collapsingChildren) {
          var children = this._collapsingChildren;
          for (var i = 0; i < children.length; i++) {
            children[i].classList.remove('ember-animated-top-collapse');
          }
        }
      }
    }, {
      key: 'startAtSprite',
      value: function startAtSprite(otherSprite) {
        (0, _emberAnimated.continueMotions)(otherSprite.element, this.element);
        var diff = this.difference('finalBounds', otherSprite, 'initialBounds');
        this.startTranslatedBy(-diff.dx, -diff.dy);
        this._initialBounds = (0, _bounds.resizedBounds)(this._initialBounds, otherSprite.initialBounds.width, otherSprite.initialBounds.height);
        this._initialComputedStyle = otherSprite.initialComputedStyle;
        this._initialCumulativeTransform = otherSprite.initialCumulativeTransform;
      }
    }, {
      key: 'startAtPixel',
      value: function startAtPixel(_ref) {
        var x = _ref.x,
            y = _ref.y;

        var dx = 0;
        var dy = 0;
        if (x != null) {
          dx = x - this._finalBounds.left;
          if (this._offsetSprite) {
            dx -= this._offsetSprite.finalBounds.left;
          }
        }
        if (y != null) {
          dy = y - this._finalBounds.top;
          if (this._offsetSprite) {
            dy -= this._offsetSprite.finalBounds.top;
          }
        }
        this.startTranslatedBy(dx, dy);
      }
    }, {
      key: 'startTranslatedBy',
      value: function startTranslatedBy(dx, dy) {
        var priorInitialBounds = this._initialBounds;
        var offsetX = 0;
        var offsetY = 0;
        if (this._offsetSprite) {
          offsetX = this._offsetSprite.finalBounds.left - this._offsetSprite.initialBounds.left;
          offsetY = this._offsetSprite.finalBounds.top - this._offsetSprite.initialBounds.top;
        }
        this._initialBounds = (0, _bounds.shiftedBounds)(this._finalBounds, dx - offsetX, dy - offsetY);

        if (this._inInitialPosition) {
          // we were already moved into our priorInitiaBounds position, so we need to compensate
          this.translate(this._initialBounds.left - priorInitialBounds.left, this._initialBounds.top - priorInitialBounds.top);
        } else {
          this.translate(this._initialBounds.left - this._finalBounds.left, this._initialBounds.top - this._finalBounds.top);
          this._inInitialPosition = true;
        }
      }
    }, {
      key: 'moveToFinalPosition',
      value: function moveToFinalPosition() {
        if (this._inInitialPosition) {
          var initial = this._initialBounds;
          var final = this._finalBounds;
          var dx = final.left - initial.left;
          var dy = final.top - initial.top;
          this.translate(dx, dy);
          this._inInitialPosition = false;
        }
      }
    }, {
      key: 'endAtSprite',
      value: function endAtSprite(otherSprite) {
        var diff = otherSprite.difference('finalBounds', this, 'initialBounds');
        this.endTranslatedBy(diff.dx, diff.dy);
        this._finalBounds = (0, _bounds.resizedBounds)(this._finalBounds, otherSprite.finalBounds.width, otherSprite.finalBounds.height);
        this._finalComputedStyle = otherSprite.finalComputedStyle;
        this._finalCumulativeTransform = otherSprite.finalCumulativeTransform;
      }
    }, {
      key: 'endAtPixel',
      value: function endAtPixel(_ref2) {
        var x = _ref2.x,
            y = _ref2.y;

        var dx = 0;
        var dy = 0;
        if (x != null) {
          dx = x - this._initialBounds.left;
          if (this._offsetSprite) {
            dx -= this._offsetSprite.initialBounds.left;
          }
        }
        if (y != null) {
          dy = y - this._initialBounds.top;
          if (this._offsetSprite) {
            dy -= this._offsetSprite.initialBounds.top;
          }
        }
        this.endTranslatedBy(dx, dy);
      }
    }, {
      key: 'endTranslatedBy',
      value: function endTranslatedBy(dx, dy) {
        this._finalBounds = (0, _bounds.shiftedBounds)(this._initialBounds, dx, dy);
      }
    }, {
      key: 'endRelativeTo',
      value: function endRelativeTo(otherSprite) {
        this.endTranslatedBy(otherSprite.finalBounds.left - otherSprite.initialBounds.left, otherSprite.finalBounds.top - otherSprite.initialBounds.top);
      }
    }, {
      key: 'initialBounds',
      get: function get() {
        return this._initialBounds;
      }
    }, {
      key: 'absoluteInitialBounds',
      get: function get() {
        if (this._offsetSprite) {
          return (0, _bounds.shiftedBounds)(this._initialBounds, this._offsetSprite.initialBounds.left, this._offsetSprite.initialBounds.top);
        } else {
          return this._initialBounds;
        }
      }
    }, {
      key: 'finalBounds',
      get: function get() {
        return this._finalBounds;
      }
    }, {
      key: 'absoluteFinalBounds',
      get: function get() {
        if (this._offsetSprite) {
          return (0, _bounds.shiftedBounds)(this._finalBounds, this._offsetSprite.finalBounds.left, this._offsetSprite.finalBounds.top);
        } else {
          return this._finalBounds;
        }
      }
    }, {
      key: 'initialComputedStyle',
      get: function get() {
        return this._initialComputedStyle;
      }
    }, {
      key: 'finalComputedStyle',
      get: function get() {
        return this._finalComputedStyle;
      }
    }, {
      key: 'initialCumulativeTransform',
      get: function get() {
        return this._initialCumulativeTransform;
      }
    }, {
      key: 'finalCumulativeTransform',
      get: function get() {
        return this._finalCumulativeTransform;
      }
    }, {
      key: 'originalInitialBounds',
      get: function get() {
        return this._originalInitialBounds;
      }
    }, {
      key: 'originalFinalBounds',
      get: function get() {
        return this._originalFinalBounds;
      }
    }, {
      key: 'element',
      set: function set(value) {
        this.__element = value;
      },
      get: function get() {
        return this.__element;
      }
    }, {
      key: 'transform',
      get: function get() {
        if (!this._transform) {
          this._transform = (0, _transform.ownTransform)(this.element);
        }
        return this._transform;
      }
    }, {
      key: 'cumulativeTransform',
      get: function get() {
        if (!this._cumulativeTransform) {
          this._cumulativeTransform = (0, _transform.cumulativeTransform)(this.element);
        }
        return this._cumulativeTransform;
      }
    }, {
      key: 'revealed',
      get: function get() {
        if (this._revealed == null) {
          this._revealed = !this.__element.classList.contains('ember-animated-hidden');
        }
        return this._revealed;
      }
    }]);

    return Sprite;
  }();

  exports.default = Sprite;


  function findOffsets(element, computedStyle, transform, offsetSprite) {
    var ownBounds = element.getBoundingClientRect();
    var left = ownBounds.left;
    var top = ownBounds.top;
    var effectiveOffsetParent = void 0;

    if (computedStyle.position !== 'fixed') {
      effectiveOffsetParent = offsetSprite.element;
    }

    if (effectiveOffsetParent) {
      if (effectiveOffsetParent.tagName === 'BODY') {
        // reading scroll off body doesn't reliably work cross browser
        left += window.scrollX;
        top += window.scrollY;
      } else {
        left += effectiveOffsetParent.scrollLeft;
        top += effectiveOffsetParent.scrollTop;
      }

      var eopComputedStyle = getComputedStyle(effectiveOffsetParent);
      if (eopComputedStyle.position !== 'static' || eopComputedStyle.transform !== 'none') {
        var eopBounds = effectiveOffsetParent.getBoundingClientRect();
        left -= eopBounds.left + parseFloat(eopComputedStyle.borderLeftWidth);
        top -= eopBounds.top + parseFloat(eopComputedStyle.borderTopWidth);

        var eopTransform = (0, _transform.cumulativeTransform)(effectiveOffsetParent);
        left /= eopTransform.a;
        top /= eopTransform.d;
      }
    }

    left -= transform.tx;
    top -= transform.ty;

    return { top: top, left: left };
  }

  var SVGNamespace = "http://www.w3.org/2000/svg";

  // We have special handling for SVG elements inside SVG documents. An
  // <svg> tag itself whose parent is not SVG doesn't need special
  // handling -- it participates in normal HTML positioning.
  function isSVG(element) {
    return element.namespaceURI === SVGNamespace && element.parentElement.namespaceURI === SVGNamespace;
  }

  // This distinguishes HTML vs SVG rules, and for HTML it compensates
  // for the fact that browsers are inconsistent in the way they report
  // offsetLeft & offsetTop for elements with a transformed ancestor
  // beneath their nearest positioned ancestor.
  function getEffectiveOffsetParent(element) {

    if (isSVG(element)) {
      var _cursor = element.parentElement;
      while (_cursor && _cursor.namespaceURI === SVGNamespace) {
        if (_cursor.tagName === 'svg') {
          return _cursor;
        }
        _cursor = _cursor.parentElement;
      }
      // we should never fall through here -- presumably we must find an
      // <svg> tag somewhere before we exit the svg namespace. But if we
      // do fall through, I'll just let this continue into the regular
      // HTML rules below.
    }

    var offsetParent = element.offsetParent;
    var cursor = element.parentElement;
    while (cursor && offsetParent && cursor !== offsetParent) {
      var styles = window.getComputedStyle(cursor);
      var t = styles.transform !== '' ? styles.transform : cursor.style.transform;
      if (t !== 'none') {
        return cursor;
      }
      cursor = cursor.parentElement;
    }
    return offsetParent;
  }

  function getSVGLength(element, property) {
    if (element[property]) {
      return element[property].baseVal.value;
    }
  }

  function setSVGLength(element, property, values) {
    if (typeof values[property] == 'number') {
      element[property].baseVal.value = values[property];
    }
  }

  function setAttribute(element, attrName, values) {
    if (values[attrName]) {
      element.setAttribute(attrName, values[attrName]);
    } else {
      element.removeAttribute(attrName);
    }
  }

  // getComputedStyle returns a *live* CSSStyleDeclaration that will
  // keep changing as the element changes. So we use this to copy off a
  // snapshot of the properties we potentially care about.
  function copyComputedStyle(element) {
    var computed = getComputedStyle(element);
    var output = Object.create(null);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = COPIED_CSS_PROPERTIES[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var property = _step.value;

        output[property] = computed[property];
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return output;
  }
});