define('ember-animated/transitions/move-over', ['exports', 'ember-animated/motions/move', 'ember-animated/motions/follow'], function (exports, _move2, _follow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toDown = exports.toUp = exports.toRight = exports.toLeft = undefined;
  exports.default = moveOver;

  var _marked = regeneratorRuntime.mark(moveOver);

  var toLeft = exports.toLeft = moveOver.bind(null, 'x', -1);
  var toRight = exports.toRight = moveOver.bind(null, 'x', 1);
  var toUp = exports.toUp = moveOver.bind(null, 'y', -1);
  var toDown = exports.toDown = moveOver.bind(null, 'y', 1);

  function normalize(dimension, direction) {
    var position = void 0;
    var size = void 0;
    var startTranslatedBy = void 0;
    var endTranslatedBy = void 0;
    if (dimension.toLowerCase() === 'x') {
      size = function size(bounds) {
        return bounds.width;
      };
      if (direction > 0) {
        position = function position(bounds) {
          return bounds.left;
        };
        startTranslatedBy = function startTranslatedBy(sprite, distance) {
          return sprite.startTranslatedBy(distance, 0);
        };
        endTranslatedBy = function endTranslatedBy(sprite, distance) {
          return sprite.endTranslatedBy(distance, 0);
        };
      } else {
        position = function position(bounds) {
          return -bounds.right;
        };
        startTranslatedBy = function startTranslatedBy(sprite, distance) {
          return sprite.startTranslatedBy(-distance, 0);
        };
        endTranslatedBy = function endTranslatedBy(sprite, distance) {
          return sprite.endTranslatedBy(-distance, 0);
        };
      }
    } else {
      size = function size(bounds) {
        return bounds.height;
      };
      if (direction > 0) {
        position = function position(bounds) {
          return bounds.top;
        };
        startTranslatedBy = function startTranslatedBy(sprite, distance) {
          return sprite.startTranslatedBy(0, distance);
        };
        endTranslatedBy = function endTranslatedBy(sprite, distance) {
          return sprite.endTranslatedBy(0, distance);
        };
      } else {
        position = function position(bounds) {
          return -bounds.bottom;
        };
        startTranslatedBy = function startTranslatedBy(sprite, distance) {
          return sprite.startTranslatedBy(0, -distance);
        };
        endTranslatedBy = function endTranslatedBy(sprite, distance) {
          return sprite.endTranslatedBy(0, -distance);
        };
      }
    }
    return { position: position, size: size, startTranslatedBy: startTranslatedBy, endTranslatedBy: endTranslatedBy };
  }

  function moveOver(dimension, direction, context) {
    var _normalize, position, size, startTranslatedBy, endTranslatedBy, viewport, offset, move, i, _move;

    return regeneratorRuntime.wrap(function moveOver$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _normalize = normalize(dimension, direction), position = _normalize.position, size = _normalize.size, startTranslatedBy = _normalize.startTranslatedBy, endTranslatedBy = _normalize.endTranslatedBy;
            viewport = void 0;

            if (!context.insertedSprites.length) {
              _context.next = 6;
              break;
            }

            viewport = context.insertedSprites[0].finalBounds;
            _context.next = 11;
            break;

          case 6:
            if (!context.keptSprites.length) {
              _context.next = 10;
              break;
            }

            viewport = context.keptSprites[0].finalBounds;
            _context.next = 11;
            break;

          case 10:
            throw new Error("Unimplemented");

          case 11:
            if (!context.insertedSprites.length) {
              _context.next = 19;
              break;
            }

            offset = 0;


            // if any leaving sprites still hang outside the viewport to the
            // left, they add to our offset because the new sprite will be to
            // their left.
            context.removedSprites.forEach(function (sprite) {
              var o = position(viewport) - position(sprite.initialBounds);
              if (o > offset) {
                offset = o;
              }
            });

            // the new sprite's own width adds to our offset because we want its
            // right edge (not left edge) to start touching the leftmost leaving
            // sprite (or viewport if no leaving sprites)
            offset += size(context.insertedSprites[0].finalBounds);

            startTranslatedBy(context.insertedSprites[0], -offset);

            if (context.removedSprites.length > 0) {
              endTranslatedBy(context.removedSprites[0], offset);
              move = new _move2.Move(context.removedSprites[0]);

              move.run();
              for (i = 1; i < context.removedSprites.length; i++) {
                (0, _follow.default)(context.removedSprites[i], { source: move });
              }
              (0, _follow.default)(context.insertedSprites[0], { source: move });
            } else {
              new _move2.Move(context.insertedSprites[0]).run();
            }
            _context.next = 26;
            break;

          case 19:
            if (!context.keptSprites.length) {
              _context.next = 25;
              break;
            }

            _move = new _move2.Move(context.keptSprites[0]);

            _move.run();
            context.removedSprites.forEach(function (sprite) {
              (0, _follow.default)(sprite, { source: _move });
            });
            _context.next = 26;
            break;

          case 25:
            throw new Error("Unimplemented2");

          case 26:
          case 'end':
            return _context.stop();
        }
      }
    }, _marked, this);
  }
});