define('ember-animated/-private/transition-context', ['exports', 'ember-animated/-private/scheduler'], function (exports, _scheduler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var TransitionContext = function () {
    function TransitionContext(duration, insertedSprites, keptSprites, removedSprites, sentSprites, receivedSprites) {
      _classCallCheck(this, TransitionContext);

      this._duration = duration;
      this._insertedSprites = insertedSprites;
      this._keptSprites = keptSprites;
      this._removedSprites = removedSprites;
      this._sentSprites = sentSprites;
      this._receivedSprites = receivedSprites;
      this._prepared = new Set();
    }

    // the following things are all accessors in order to make them
    // read-only, and to let us tell which classes of sprites a user's
    // transition is actually using.

    _createClass(TransitionContext, [{
      key: '_prepareSprites',
      value: function _prepareSprites(sprites) {
        var _this = this;

        // Link them up, so that users can conveniently pass sprites
        // around to Motions without also passing the transition context.
        sprites.forEach(function (sprite) {
          return sprite._transitionContext = _this;
        });

        if (!this.prepareSprite) {
          return sprites;
        }
        return sprites.map(function (sprite) {
          if (!_this._prepared.has(sprite)) {
            _this._prepared.add(sprite);
            sprite = _this.prepareSprite(sprite);
          }
          return sprite;
        });
      }
    }, {
      key: '_runToCompletion',
      value: regeneratorRuntime.mark(function _runToCompletion(transition) {
        return regeneratorRuntime.wrap(function _runToCompletion$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.delegateYield(transition(this), 't0', 1);

              case 1:
                _context.next = 3;
                return (0, _scheduler.childrenSettled)();

              case 3:
              case 'end':
                return _context.stop();
            }
          }
        }, _runToCompletion, this);
      })
    }, {
      key: 'duration',
      get: function get() {
        return this._duration;
      }
    }, {
      key: 'insertedSprites',
      get: function get() {
        return this._prepareSprites(this._insertedSprites);
      }
    }, {
      key: 'keptSprites',
      get: function get() {
        return this._prepareSprites(this._keptSprites);
      }
    }, {
      key: 'removedSprites',
      get: function get() {
        return this._prepareSprites(this._removedSprites);
      }
    }, {
      key: 'sentSprites',
      get: function get() {
        return this._prepareSprites(this._sentSprites);
      }
    }, {
      key: 'receivedSprites',
      get: function get() {
        return this._prepareSprites(this._receivedSprites);
      }
    }]);

    return TransitionContext;
  }();

  exports.default = TransitionContext;
});