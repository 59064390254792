define('ember-simple-auth-token/authenticators/jwt', ['exports', 'ember-simple-auth-token/authenticators/token', 'ember-get-config'], function (exports, _token, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _token.default.extend({
    /**
      @method init
      @private
    */
    init: function init() {
      this._super.apply(this, arguments);
      var conf = _emberGetConfig.default['ember-simple-auth-token'] || {};
      this.tokenDataPropertyName = conf.tokenDataPropertyName || 'tokenData';
      this.refreshAccessTokens = conf.refreshAccessTokens === false ? false : true;
      this.tokenExpirationInvalidateSession = conf.tokenExpirationInvalidateSession === false ? false : true;
      this.serverTokenRefreshEndpoint = conf.serverTokenRefreshEndpoint || '/api/token-refresh/';
      this.refreshTokenPropertyName = conf.refreshTokenPropertyName || 'refresh_token';
      this.tokenExpireName = conf.tokenExpireName || 'exp';
      this.refreshLeeway = conf.refreshLeeway || 0;
    },


    /**
      Restores the session from a set of session properties.
       It will return a resolving promise if one of two conditions is met:
       1) Both `data.token` and `data.expiresAt` are non-empty and `expiresAt`
         is greater than the calculated `now`.
      2) If `data.token` is non-empty and the decoded token has a key for
         `tokenExpireName`.
       If `refreshAccessTokens` is true, `scheduleAccessTokenRefresh` will
      be called and an automatic token refresh will be initiated.
       @method restore
      @param {Object} data The data to restore the session from
      @return {Promise} A promise that when it resolves results
                                   in the session being authenticated
    */
    restore: function restore(data) {
      var _this = this;

      var dataObject = Ember.Object.create(data);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var now = _this.getCurrentTime();
        var token = dataObject.get(_this.tokenPropertyName);
        var refreshToken = dataObject.get(_this.refreshTokenPropertyName);
        var expiresAt = dataObject.get(_this.tokenExpireName);

        if (Ember.isEmpty(token)) {
          return reject(new Error('empty token'));
        }

        if (Ember.isEmpty(expiresAt)) {
          // Fetch the expire time from the token data since `expiresAt`
          // wasn't included in the data object that was passed in.
          var tokenData = _this.getTokenData(token);
          expiresAt = tokenData[_this.tokenExpireName];
          if (Ember.isEmpty(expiresAt)) {
            return resolve(data);
          }
        }

        if (expiresAt > now) {
          var wait = (expiresAt - now - _this.refreshLeeway) * 1000;

          if (_this.tokenExpirationInvalidateSession) {
            _this.scheduleAccessTokenExpiration(expiresAt);
          }

          if (wait > 0) {
            if (_this.refreshAccessTokens) {
              _this.scheduleAccessTokenRefresh(dataObject.get(_this.tokenExpireName), refreshToken);
            }
            return resolve(data);
          } else if (_this.refreshAccessTokens) {
            return resolve(_this.refreshAccessToken(refreshToken));
          } else {
            return reject(new Error('unable to refresh token'));
          }
        } else {
          // the refresh token might not be expired,
          // we can't test this on the client so attempt to refresh the token.
          // If the server rejects the token the user session will be invalidated
          if (_this.refreshAccessTokens) {
            return resolve(_this.refreshAccessToken(refreshToken));
          } else {
            return reject(new Error('token is expired'));
          }
        }
      });
    },


    /**
      Authenticates the session with the specified `credentials`.
       It will return a resolving promise if it successfully posts a request
      to the `JWT.serverTokenEndpoint` with the valid credentials.
       An automatic token refresh will be scheduled with the new expiration date
      from the returned refresh token. That expiration will be merged with the
      response and the promise resolved.
       @method authenticate
      @param {Object} credentials The credentials to authenticate the session with
      @param {Object} headers Optional headers to send with the authentication request
      @return {Promise} A promise that resolves when an auth token is
                                   successfully acquired from the server and rejects
                                   otherwise
    */
    authenticate: function authenticate(credentials, headers) {
      var _this2 = this;

      return this.makeRequest(this.serverTokenEndpoint, credentials, Ember.assign({}, this.headers, headers)).then(function (response) {
        return _this2.handleAuthResponse(response.json);
      });
    },


    /**
      Schedules a token refresh request to be sent to the backend after a calculated
      `wait` time has passed.
       If both `token` and `expiresAt` are non-empty, and `expiresAt` minus the optional
      refres leeway is greater than the calculated `now`, the token refresh will be scheduled
      through later.
       @method scheduleAccessTokenRefresh
      @private
    */
    scheduleAccessTokenRefresh: function scheduleAccessTokenRefresh(expiresAt, refreshToken) {
      if (this.refreshAccessTokens) {

        var now = this.getCurrentTime();
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;

        if (!Ember.isEmpty(refreshToken) && !Ember.isEmpty(expiresAt)) {
          if (wait > 0) {
            Ember.run.cancel(this._refreshTokenTimeout);
            delete this._refreshTokenTimeout;
            this._refreshTokenTimeout = Ember.run.later(this, this.refreshAccessToken, refreshToken, wait);
          } else if (expiresAt > now) {
            throw new Error('refreshLeeway is too large which is preventing token refresh');
          }
        }
      }
    },


    /**
      Makes a refresh token request to grab a new authenticated JWT token from the server.
       It will return a resolving promise if a successful POST is made to the
      `JWT.serverTokenRefreshEndpoint`.
       After the new token is obtained it will schedule the next automatic token refresh
      based on the new `expiresAt` time.
       The session will be updated via the trigger `sessionDataUpdated`.
       @method refreshAccessToken
      @private
    */
    refreshAccessToken: function refreshAccessToken(token) {
      var _this3 = this;

      var data = this.makeRefreshData(token);

      return this.makeRequest(this.serverTokenRefreshEndpoint, data, this.headers).then(function (response) {
        var sessionData = _this3.handleAuthResponse(response.json);
        _this3.trigger('sessionDataUpdated', sessionData);
        return sessionData;
      }).catch(function (error) {
        _this3.handleTokenRefreshFail(error.status);
        return Ember.RSVP.Promise.reject(error);
      });
    },


    /**
      Returns a nested object with the token property name.
      Example:  If `refreshTokenPropertyName` is "data.user.refreshToken", `makeRefreshData` will return {data: {user: {refreshToken: "token goes here"}}}
       @method makeRefreshData
      @return {object} An object with the nested property name.
    */
    makeRefreshData: function makeRefreshData(refreshToken) {
      var data = {};
      var nestings = this.refreshTokenPropertyName.split('.');
      var refreshTokenPropertyName = nestings.pop();
      var lastObject = data;

      nestings.forEach(function (nesting) {
        lastObject[nesting] = {};
        lastObject = lastObject[nesting];
      });

      lastObject[refreshTokenPropertyName] = refreshToken;

      return data;
    },


    /**
      Returns the decoded token with accessible returned values.
       @method getTokenData
      @return {object} An object with properties for the session.
    */
    getTokenData: function getTokenData(token) {
      var payload = token.split('.')[1];
      var tokenData = decodeURIComponent(window.escape(atob(payload.replace(/-/g, '+').replace(/_/g, '/'))));

      try {
        return JSON.parse(tokenData);
      } catch (error) {
        return tokenData;
      }
    },


    /**
      Cancels any outstanding automatic token refreshes and returns a resolving
      promise.
      @method invalidate
      @param {Object} data The data of the session to be invalidated
      @return {Promise} A resolving promise
    */
    invalidate: function invalidate() {
      Ember.run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      Ember.run.cancel(this._tokenExpirationTimeout);
      delete this._tokenExpirationTimeout;
      return Ember.RSVP.resolve();
    },


    /**
      Returns the current time as a timestamp in seconds
      @method getCurrentTime
      @return {Integer} timestamp
    */
    getCurrentTime: function getCurrentTime() {
      return Math.floor(new Date().getTime() / 1000);
    },


    /**
      Handles authentication response from server, and returns session data
       @method handleAuthResponse
      @private
     */
    handleAuthResponse: function handleAuthResponse(response) {
      var token = Ember.get(response, this.tokenPropertyName);

      if (Ember.isEmpty(token)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      var tokenData = this.getTokenData(token);
      var expiresAt = Ember.get(tokenData, this.tokenExpireName);
      var tokenExpireData = {};

      tokenExpireData[this.tokenExpireName] = expiresAt;

      if (this.tokenExpirationInvalidateSession) {
        this.scheduleAccessTokenExpiration(expiresAt);
      }

      if (this.refreshAccessTokens) {
        var refreshToken = Ember.get(response, this.refreshTokenPropertyName);

        if (Ember.isEmpty(refreshToken)) {
          throw new Error('Refresh token is empty. Please check your backend response.');
        }

        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }

      return Ember.assign(response, tokenExpireData, { tokenData: tokenData });
    },


    /**
      Handles token refresh fail status. If the server response to a token refresh has a
      status of 401 or 403 then the token in the session will be invalidated and
      the sessionInvalidated provided by ember-simple-auth will be triggered.
       @method handleTokenRefreshFail
    */

    handleTokenRefreshFail: function handleTokenRefreshFail(refreshStatus) {
      var _this4 = this;

      if (refreshStatus === 401 || refreshStatus === 403) {
        return this.invalidate().then(function () {
          _this4.trigger('sessionDataInvalidated');
        });
      }
    },


    /**
      Schedules session invalidation at the time token expires.
       @method scheduleAccessTokenExpiration
      @private
    */
    scheduleAccessTokenExpiration: function scheduleAccessTokenExpiration(expiresAt) {
      var now = this.getCurrentTime();
      var wait = Math.max((expiresAt - now) * 1000, 0);

      if (!Ember.isEmpty(expiresAt)) {
        Ember.run.cancel(this._tokenExpirationTimeout);
        delete this._tokenExpirationTimeout;
        this._tokenExpirationTimeout = Ember.run.later(this, this.handleAccessTokenExpiration, wait);
      }
    },


    /**
      Handles access token expiration
       @method handleAccessTokenExpiration
      @private
    */
    handleAccessTokenExpiration: function handleAccessTokenExpiration() {
      var _this5 = this;

      return this.invalidate().then(function () {
        _this5.trigger('sessionDataInvalidated');
      });
    }
  });
});