define('ember-google-maps/components/g-map/control', ['exports', 'ember-google-maps/components/g-map/map-component', 'ember-google-maps/templates/components/g-map/control'], function (exports, _mapComponent, _control) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mapComponent.default.extend({
    layout: _control.default,
    tagName: 'div',

    _type: 'control',
    _requiredOptions: ['position'],

    _addComponent: function _addComponent() {
      var _elementDestination = Ember.set(this, '_elementDestination', document.createElement('div'));
      var map = Ember.get(this, 'map');

      var controlPosition = google.maps.ControlPosition[Ember.get(this, 'position')];
      map.controls[controlPosition].push(_elementDestination);

      Ember.set(this, 'mapComponent', _elementDestination);
    },
    _updateComponent: function _updateComponent() {}
  });
});