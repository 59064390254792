define('ember-animated/transitions/fade', ['exports', 'ember-animated/motions/opacity', 'ember-animated'], function (exports, _opacity, _emberAnimated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _callee;

  var _marked = regeneratorRuntime.mark(_callee);

  function _callee(_ref) {
    var removedSprites = _ref.removedSprites,
        insertedSprites = _ref.insertedSprites,
        keptSprites = _ref.keptSprites,
        duration = _ref.duration;
    return regeneratorRuntime.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.next = 2;
            return _emberAnimated.Promise.all(removedSprites.map(function (s) {
              if (s.revealed) {
                return (0, _opacity.default)(s, {
                  to: 0,
                  duration: duration / 2
                });
              }
            }));

          case 2:

            // Once all fading out has happened, we can fade in the inserted
            // or kept sprites. Note that we get keptSprites if some things
            // were fading out and then we get interrupted and decide to
            // keep them around after all.
            insertedSprites.concat(keptSprites).map(function (s) {
              return (0, _opacity.default)(s, {
                to: 1,
                duration: duration / 2
              });
            });

          case 3:
          case 'end':
            return _context.stop();
        }
      }
    }, _marked, this);
  }
});