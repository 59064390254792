define('ember-animated/-private/ember-scheduler', ['exports', 'ember-animated/-private/scheduler', 'ember-animated'], function (exports, _scheduler, _emberAnimated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.task = task;
  exports.timeout = timeout;

  var _marked = regeneratorRuntime.mark(withRunLoop);

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function task() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return new (Function.prototype.bind.apply(TaskProperty, [null].concat(args)))();
  }

  function TaskProperty(taskFn) {
    var tp = this;
    Ember.ComputedProperty.call(this, function (name) {
      return new Task(this, taskFn, tp, name);
    });
    this._bufferPolicy = null;
    this._observes = null;
  }

  TaskProperty.prototype = Object.create(Ember.ComputedProperty.prototype);
  Ember.assign(TaskProperty.prototype, {
    constructor: TaskProperty,
    restartable: function restartable() {
      this._bufferPolicy = cancelAllButLast;
      return this;
    },
    drop: function drop() {
      this._bufferPolicy = _drop;
      return this;
    },
    observes: function observes() {
      for (var _len2 = arguments.length, deps = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        deps[_key2] = arguments[_key2];
      }

      this._observes = deps;
      return this;
    },
    setup: function setup(proto, taskName) {
      registerOnPrototype(Ember.addObserver, proto, this._observes, taskName, 'perform', true);
    }
  });

  var priv = new WeakMap();

  var Task = function () {
    function Task(context, implementation, taskProperty, name) {
      _classCallCheck(this, Task);

      priv.set(this, {
        context: context,
        implementation: implementation,
        instances: [],
        taskProperty: taskProperty,
        name: name
      });
      this.concurrency = 0;
      this.isRunning = false;
    }

    _createClass(Task, [{
      key: 'perform',
      value: function perform() {
        for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        var self = this;
        var privSelf = priv.get(this);
        var context = privSelf.context;
        var implementation = privSelf.implementation;
        var policy = privSelf.taskProperty._bufferPolicy;
        if (context.isDestroyed) {
          throw new Error('Tried to perform task ' + privSelf.name + ' on an already destroyed object');
        }
        cleanupOnDestroy(context, this, 'cancelAll');
        return (0, _scheduler.spawn)(regeneratorRuntime.mark(function _callee() {
          var maybeWait, finalValue;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (true) {
                    (0, _scheduler.logErrors)(function (error) {
                      if (Ember.testing) {
                        Ember.Test.adapter.exception(error);
                      } else {
                        (0, _emberAnimated.microwait)().then(function () {
                          throw error;
                        });
                      }
                    });
                  }

                  _context.prev = 1;

                  self._addInstance((0, _scheduler.current)());

                  if (!policy) {
                    _context.next = 8;
                    break;
                  }

                  maybeWait = policy(self, privSelf);

                  if (!maybeWait) {
                    _context.next = 8;
                    break;
                  }

                  _context.next = 8;
                  return maybeWait;

                case 8:
                  return _context.delegateYield(withRunLoop(implementation.call.apply(implementation, [context].concat(args))), 't0', 9);

                case 9:
                  finalValue = _context.t0;
                  return _context.abrupt('return', finalValue);

                case 11:
                  _context.prev = 11;

                  Ember.run.join(function () {
                    self._removeInstance((0, _scheduler.current)());
                  });
                  return _context.finish(11);

                case 14:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[1,, 11, 14]]);
        }));
      }
    }, {
      key: 'cancelAll',
      value: function cancelAll() {
        priv.get(this).instances.forEach(function (i) {
          return (0, _scheduler.stop)(i);
        });
      }
    }, {
      key: '_addInstance',
      value: function _addInstance(i) {
        priv.get(this).instances.push(i);
        Ember.set(this, 'isRunning', true);
        Ember.set(this, 'concurrency', this.concurrency + 1);
      }
    }, {
      key: '_removeInstance',
      value: function _removeInstance(i) {
        var instances = priv.get(this).instances;
        instances.splice(instances.indexOf(i), 1);
        Ember.set(this, 'concurrency', this.concurrency - 1);
        Ember.set(this, 'isRunning', this.concurrency > 0);
      }
    }, {
      key: '_safeInvokeCallback',
      value: function _safeInvokeCallback(method, args) {
        var _priv$get = priv.get(this),
            context = _priv$get.context;

        if (!context.isDestroyed) {
          this[method].apply(this, args);
        }
      }
    }]);

    return Task;
  }();

  // cribbed from machty's ember-concurrency
  function cleanupOnDestroy(owner, object, cleanupMethodName) {
    if (!owner.willDestroy) {
      // we're running in non Ember object (possibly in a test mock)
      return;
    }

    if (!owner.willDestroy.__ember_processes_destroyers__) {
      var oldWillDestroy = owner.willDestroy;
      var disposers = [];

      owner.willDestroy = function () {
        for (var i = 0, l = disposers.length; i < l; i++) {
          disposers[i]();
        }
        oldWillDestroy.apply(owner, arguments);
      };
      owner.willDestroy.__ember_processes_destroyers__ = disposers;
    }

    owner.willDestroy.__ember_processes_destroyers__.push(function () {
      try {
        object[cleanupMethodName]();
      } catch (err) {
        if (err.message !== 'TaskCancelation') {
          throw err;
        }
      }
    });
  }
  function registerOnPrototype(addListenerOrObserver, proto, names, taskName, taskMethod, once) {
    if (names) {
      for (var i = 0; i < names.length; ++i) {
        var name = names[i];
        addListenerOrObserver(proto, name, null, makeTaskCallback(taskName, taskMethod, once));
      }
    }
  }
  function makeTaskCallback(taskName, method, once) {
    return function () {
      var task = this.get(taskName);

      for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      if (once) {
        Ember.run.scheduleOnce('actions', task, '_safeInvokeCallback', method, args);
      } else {
        task._safeInvokeCallback(method, args);
      }
    };
  }

  function cancelAllButLast(task, privTask) {
    var instances = privTask.instances;
    for (var i = 0; i < instances.length - 1; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }

  function _drop(task, privTask) {
    var instances = privTask.instances;
    for (var i = 1; i < instances.length; i++) {
      (0, _scheduler.stop)(instances[i]);
    }
  }

  function withRunLoop(generator) {
    var state, nextValue, fulfilled;
    return regeneratorRuntime.wrap(function withRunLoop$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            state = void 0;
            nextValue = void 0;
            fulfilled = true;

          case 3:
            if (!true) {
              _context2.next = 22;
              break;
            }

            Ember.run.join(function () {
              try {
                if (fulfilled) {
                  state = generator.next(nextValue);
                } else {
                  state = generator.throw(nextValue);
                }
              } catch (err) {
                state = {
                  threw: err
                };
              }
            });

            if (!state.done) {
              _context2.next = 7;
              break;
            }

            return _context2.abrupt('return', state.value);

          case 7:
            if (!state.threw) {
              _context2.next = 9;
              break;
            }

            throw state.threw;

          case 9:
            _context2.prev = 9;
            _context2.next = 12;
            return state.value;

          case 12:
            nextValue = _context2.sent;

            fulfilled = true;
            _context2.next = 20;
            break;

          case 16:
            _context2.prev = 16;
            _context2.t0 = _context2['catch'](9);

            nextValue = _context2.t0;
            fulfilled = false;

          case 20:
            _context2.next = 3;
            break;

          case 22:
          case 'end':
            return _context2.stop();
        }
      }
    }, _marked, this, [[9, 16]]);
  }

  function timeout(ms) {
    return new Ember.RSVP.Promise(function (resolve) {
      return setTimeout(resolve, ms);
    });
  }
});