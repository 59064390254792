define('ember-animated/services/motion', ['exports', 'ember-animated/-private/ember-scheduler', 'ember-animated', 'ember-animated/components/animated-beacon'], function (exports, _emberScheduler, _emberAnimated, _animatedBeacon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _marked = regeneratorRuntime.mark(ancestorsOf);

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var MotionService = Ember.Service.extend({
    init: function init() {
      this._super();
      this._rendezvous = [];
      this._measurements = [];
      this._animators = Ember.A();
      this._orphanObserver = null;
      this._animationObservers = [];
      this._descendantObservers = [];
      this._ancestorObservers = new WeakMap();
    },
    register: function register(animator) {
      this._animators.pushObject(animator);
      return this;
    },
    unregister: function unregister(animator) {
      this._animators.removeObject(animator);
      return this;
    },
    observeOrphans: function observeOrphans(fn) {
      if (this._orphanObserver) {
        throw new Error("Only one animated-orphans component can be used at one time");
      }
      this._orphanObserver = fn;
      return this;
    },
    unobserveOrphans: function unobserveOrphans(fn) {
      if (this._orphanObserver === fn) {
        this._orphanObserver = null;
      }
      return this;
    },
    observeAnimations: function observeAnimations(fn) {
      this._animationObservers.push(fn);
      return this;
    },
    unobserveAnimations: function unobserveAnimations(fn) {
      var index = this._animationObservers.indexOf(fn);
      if (index !== -1) {
        this._animationObservers.splice(index, 1);
      }
      return this;
    },
    observeDescendantAnimations: function observeDescendantAnimations(component, fn) {
      this._descendantObservers.push({ component: component, fn: fn });
      return this;
    },
    unobserveDescendantAnimations: function unobserveDescendantAnimations(component, fn) {
      var entry = this._descendantObservers.find(function (e) {
        return e.component === component && e.fn === fn;
      });
      if (entry) {
        this._descendantObservers.splice(this._descendantObservers.indexOf(entry), 1);
      }
      return this;
    },
    observeAncestorAnimations: function observeAncestorAnimations(component, fn) {
      var id = void 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = ancestorsOf(component)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var ancestorComponent = _step.value;

          // when we find an animated list element, we save its ID
          if (ancestorComponent.isEmberAnimatedListElement) {
            id = ancestorComponent.get('child.id');
          } else if (id != null) {
            // if we found an ID on the last loop, now we've got the list
            // element's parent which is the actual animator.
            var observers = this._ancestorObservers.get(ancestorComponent);
            if (!observers) {
              this._ancestorObservers.set(ancestorComponent, observers = new Map());
            }
            observers.set(fn, id);
            id = null;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return this;
    },
    unobserveAncestorAnimations: function unobserveAncestorAnimations(component, fn) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = ancestorsOf(component)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var ancestorComponent = _step2.value;

          var observers = this._ancestorObservers.get(ancestorComponent);
          if (observers) {
            observers.delete(fn);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return this;
    },


    // This is a publicly visible property you can use to know if any
    // animations are running. It's timing is deliberately not
    // synchronous, so that you can bind it into a template without
    // getting double-render errors.
    isAnimating: Ember.computed(function () {
      return this.get('isAnimatingSync');
    }),

    // Synchronously updated version of isAnimating. If you try to
    // depend on this in a template you will get double-render errors
    // (because the act of rendering can cause animations to begin).
    isAnimatingSync: Ember.computed('_animators.@each.isAnimating', function () {
      return this.get('_animators').any(function (animator) {
        return animator.get('isAnimating');
      });
    }),

    // Invalidation support for isAnimating
    _invalidateIsAnimating: (0, _emberScheduler.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberAnimated.rAF)();

            case 2:
              this.notifyPropertyChange('isAnimating');

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).observes('isAnimatingSync'),

    waitUntilIdle: (0, _emberScheduler.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!true) {
                _context2.next = 10;
                break;
              }

              _context2.next = 3;
              return (0, _emberAnimated.rAF)();

            case 3:
              if (this.get('isAnimatingSync')) {
                _context2.next = 8;
                break;
              }

              _context2.next = 6;
              return (0, _emberAnimated.rAF)();

            case 6:
              if (this.get('isAnimatingSync')) {
                _context2.next = 8;
                break;
              }

              return _context2.abrupt('return');

            case 8:
              _context2.next = 0;
              break;

            case 10:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    matchDestroyed: function matchDestroyed(removed, transition, duration, shouldAnimateRemoved) {
      if (this._orphanObserver && removed.length > 0) {
        // if these orphaned sprites may be capable of animating,
        // delegate them to the orphanObserver. It will do farMatching
        // for them.
        this._orphanObserver(removed, transition, duration, shouldAnimateRemoved);
      } else {
        // otherwise, we make them available for far matching but they
        // can't be animated.
        this.get('farMatch').perform(null, [], [], removed, true);
      }
    },


    farMatch: (0, _emberScheduler.task)(regeneratorRuntime.mark(function _callee3(runAnimationTask, inserted, kept, removed) {
      var longWait = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var matches, mine;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              matches = new Map();
              mine = { inserted: inserted, kept: kept, removed: removed, matches: matches, runAnimationTask: runAnimationTask, otherTasks: new Map() };

              this._rendezvous.push(mine);
              _context3.next = 5;
              return (0, _emberAnimated.microwait)();

            case 5:
              if (!longWait) {
                _context3.next = 12;
                break;
              }

              _context3.next = 8;
              return (0, _emberAnimated.afterRender)();

            case 8:
              _context3.next = 10;
              return (0, _emberAnimated.microwait)();

            case 10:
              _context3.next = 12;
              return (0, _emberAnimated.microwait)();

            case 12:

              if (this.get('farMatch.concurrency') > 1) {
                this._rendezvous.forEach(function (target) {
                  if (target === mine) {
                    return;
                  }
                  performMatches(mine, target);
                  performMatches(target, mine);
                });
              }
              this._rendezvous.splice(this._rendezvous.indexOf(mine), 1);
              return _context3.abrupt('return', {
                farMatches: matches,
                matchingAnimatorsFinished: (0, _emberAnimated.allSettled)([].concat(_toConsumableArray(mine.otherTasks.keys())))
              });

            case 15:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    willAnimate: function willAnimate(_ref) {
      var task = _ref.task,
          duration = _ref.duration,
          component = _ref.component,
          children = _ref.children;

      var message = { task: task, duration: duration };

      // tell any of our ancestors who are observing their descendants
      var ancestors = [].concat(_toConsumableArray(ancestorsOf(component)));
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this._descendantObservers[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var _ref2 = _step3.value;
          var observingComponent = _ref2.component;
          var fn = _ref2.fn;

          if (ancestors.indexOf(observingComponent) !== -1) {
            fn(message);
          }
        }

        // tell any of our descendants who are observing their ancestors
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      var observers = this._ancestorObservers.get(component);
      if (observers) {
        var _loop = function _loop(id, _fn) {
          var child = children.find(function (child) {
            return child.id === id;
          });
          if (child) {
            _fn(child.state);
          } // the else case here applies to descendants that are about
          // to be unrendered (not animated away -- immediately
          // dropped). They will still have an opportunity to animate
          // too, but they do it via their own willDestroyElement
          // hook, not the this early-warning hook.
        };

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = observers.entries()[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var _ref3 = _step4.value;

            var _ref4 = _slicedToArray(_ref3, 2);

            var _fn = _ref4[0];
            var id = _ref4[1];

            _loop(id, _fn);
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      }

      // tell anybody who is listening for all animations
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = this._animationObservers[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _fn2 = _step5.value;

          _fn2(message);
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }
    },


    staticMeasurement: regeneratorRuntime.mark(function staticMeasurement(fn) {
      var measurement, animators;
      return regeneratorRuntime.wrap(function staticMeasurement$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              measurement = { fn: fn, resolved: false, value: null };

              this._measurements.push(measurement);
              _context4.prev = 2;
              _context4.next = 5;
              return (0, _emberAnimated.microwait)();

            case 5:

              if (!measurement.resolved) {
                animators = this.get('_animators');

                animators.forEach(function (animator) {
                  return animator.beginStaticMeasurement();
                });
                this._measurements.forEach(function (m) {
                  try {
                    m.value = m.fn();
                  } catch (err) {
                    setTimeout(function () {
                      throw err;
                    }, 0);
                  }
                  m.resolved = true;
                });
                animators.forEach(function (animator) {
                  return animator.endStaticMeasurement();
                });
              }
              return _context4.abrupt('return', measurement.value);

            case 7:
              _context4.prev = 7;

              this._measurements.splice(this._measurements.indexOf(measurement), 1);
              return _context4.finish(7);

            case 10:
            case 'end':
              return _context4.stop();
          }
        }
      }, staticMeasurement, this, [[2,, 7, 10]]);
    })

  });

  function performMatches(sink, source) {
    sink.inserted.concat(sink.kept).forEach(function (sprite) {
      var match = source.removed.find(function (mySprite) {
        return sprite.owner.group == mySprite.owner.group && (sprite.owner.id === _animatedBeacon.WILDCARD || mySprite.owner.id === _animatedBeacon.WILDCARD || sprite.owner.id === mySprite.owner.id);
      });
      if (match) {
        sink.matches.set(sprite, match);
        sink.otherTasks.set(source.runAnimationTask, true);
        source.matches.set(match, sprite);
        source.otherTasks.set(sink.runAnimationTask, true);
      }
    });
  }

  function ancestorsOf(component) {
    var pointer;
    return regeneratorRuntime.wrap(function ancestorsOf$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            pointer = component.parentView;

          case 1:
            if (!pointer) {
              _context5.next = 7;
              break;
            }

            _context5.next = 4;
            return pointer;

          case 4:
            pointer = pointer.parentView;
            _context5.next = 1;
            break;

          case 7:
          case 'end':
            return _context5.stop();
        }
      }
    }, _marked, this);
  }

  exports.default = MotionService;
});