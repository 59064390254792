define('ember-animated/-private/transform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cumulativeTransform = cumulativeTransform;
  exports.ownTransform = ownTransform;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Transform = function () {
    function Transform(a, b, c, d, tx, ty) {
      _classCallCheck(this, Transform);

      // WARNING: never mutate an existing Transform. Some of them are
      // shared. Operations need to return new Transforms instead.
      this.a = a;
      this.b = b;
      this.c = c;
      this.d = d;
      this.tx = tx;
      this.ty = ty;
    }

    _createClass(Transform, [{
      key: 'serialize',
      value: function serialize() {
        if (this.isIdentity()) {
          return 'none';
        }
        return 'matrix(' + this.a + ', ' + this.b + ', ' + this.c + ', ' + this.d + ', ' + this.tx + ', ' + this.ty + ')';
      }
    }, {
      key: 'isIdentity',
      value: function isIdentity() {
        return this === identity || this.a === 1 && this.b === 0 && this.c === 0 && this.d === 1 && this.tx === 0 && this.ty === 0;
      }
    }, {
      key: 'mult',
      value: function mult(other) {
        // This is deliberately not isIdentity(). I'm optimizing for the
        // case where there was no preexisting transform at all.
        if (this === identity) {
          return other;
        }
        if (other === identity) {
          return this;
        }
        return new Transform(this.a * other.a + this.c * other.b, this.b * other.a + this.d * other.b, this.a * other.c + this.c * other.d, this.b * other.c + this.d * other.d, this.a * other.tx + this.c * other.ty + this.tx, this.b * other.tx + this.d * other.ty + this.ty);
      }
    }]);

    return Transform;
  }();

  exports.default = Transform;


  // WARNING: this constant matrix exists as an optimization. But not
  // every no-op transform triple-equals this value. If you apply two
  // transforms that cancel each other out, you will get an identity
  // matrix but it will not triple-equal this one. And that is OK: we
  // use the triple-equality as an optimization only, not for
  // correctness.
  //
  // The optimization should be worthwhile because the majority of
  // things start out with no preexisting Transform, which we can
  // represent as `identity`, which will make identity.mult(something) a
  // no-op, etc.
  var identity = exports.identity = new Transform(1, 0, 0, 1, 0, 0);

  var matrixPattern = /matrix\((.*)\)/;

  function parseTransform(matrixString) {
    var match = matrixPattern.exec(matrixString);
    if (!match) {
      return identity;
    }
    return new (Function.prototype.bind.apply(Transform, [null].concat(_toConsumableArray(match[1].split(',').map(parseFloat)))))();
  }

  function parseOrigin(originString) {
    return originString.split(' ').map(parseFloat);
  }

  /**
    Returns a Transform instance representing the cumulative CSS
    transform of this element and all its ancestors.
  
    @function cumulativeTransform
    @param {HTMLElement} elt
    @return {Transform}
  */
  function cumulativeTransform(elt) {
    var accumulator = null;
    while (elt && elt.nodeType === 1) {
      var transform = ownTransform(elt);
      if (transform !== identity && !transform.isIdentity()) {
        if (accumulator) {
          accumulator = transform.mult(accumulator);
        } else {
          accumulator = transform;
        }
      }
      elt = elt.parentElement;
    }
    return accumulator || identity;
  }

  /**
    Returns a Transform instance representing the CSS transform of this
    element.
  
   * @function ownTransform
   * @param {HTMLElement} elt
   * @return {Transform} instance representing this element's css transform property.
   */
  function ownTransform(elt) {
    var eltStyles = window.getComputedStyle(elt);
    var t = eltStyles.transform !== '' ? eltStyles.transform : elt.style.transform;
    if (t === 'none') {
      // This constant value is an optimization, and we rely on that in
      // cumulativeTransform
      return identity;
    }
    var matrix = parseTransform(t);
    if (matrix.a !== 1 || matrix.b !== 0 || matrix.c !== 0 || matrix.d !== 1) {
      // If there is any rotation, scaling, or skew we need to do it within the context of transform-origin.
      var origin = eltStyles.transformOrigin !== '' ? eltStyles.transformOrigin : elt.style.transformOrigin;

      var _parseOrigin = parseOrigin(origin),
          _parseOrigin2 = _slicedToArray(_parseOrigin, 2),
          originX = _parseOrigin2[0],
          originY = _parseOrigin2[1];

      if (originX === 0 && originY === 0) {
        // transform origin is at 0,0 so it will have no effect, so we're done.
        return matrix;
      }

      return new Transform(1, 0, 0, 1, originX, originY).mult(matrix).mult(new Transform(1, 0, 0, 1, -originX, -originY));
    } else {
      // This case is an optimization for when there is only translation.
      return matrix;
    }
  }
});