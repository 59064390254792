define('ember-animated/index', ['exports', 'ember-animated/-private/concurrency-helpers', 'ember-animated/-private/scheduler', 'ember-animated/-private/ember-scheduler', 'ember-animated/-private/debug', 'ember-animated/-private/motion', 'ember-animated/-private/tween'], function (exports, _concurrencyHelpers, _scheduler, _emberScheduler, _debug, _motion, _tween) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'rAF', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.rAF;
    }
  });
  Object.defineProperty(exports, 'microwait', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.microwait;
    }
  });
  Object.defineProperty(exports, 'wait', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.wait;
    }
  });
  Object.defineProperty(exports, 'afterRender', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.afterRender;
    }
  });
  Object.defineProperty(exports, 'clock', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.clock;
    }
  });
  Object.defineProperty(exports, 'allSettled', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.allSettled;
    }
  });
  Object.defineProperty(exports, 'Promise', {
    enumerable: true,
    get: function () {
      return _concurrencyHelpers.Promise;
    }
  });
  Object.defineProperty(exports, 'spawn', {
    enumerable: true,
    get: function () {
      return _scheduler.spawn;
    }
  });
  Object.defineProperty(exports, 'spawnChild', {
    enumerable: true,
    get: function () {
      return _scheduler.spawnChild;
    }
  });
  Object.defineProperty(exports, 'stop', {
    enumerable: true,
    get: function () {
      return _scheduler.stop;
    }
  });
  Object.defineProperty(exports, 'current', {
    enumerable: true,
    get: function () {
      return _scheduler.current;
    }
  });
  Object.defineProperty(exports, 'childrenSettled', {
    enumerable: true,
    get: function () {
      return _scheduler.childrenSettled;
    }
  });
  Object.defineProperty(exports, 'parallel', {
    enumerable: true,
    get: function () {
      return _scheduler.parallel;
    }
  });
  Object.defineProperty(exports, 'serial', {
    enumerable: true,
    get: function () {
      return _scheduler.serial;
    }
  });
  Object.defineProperty(exports, 'task', {
    enumerable: true,
    get: function () {
      return _emberScheduler.task;
    }
  });
  Object.defineProperty(exports, 'printSprites', {
    enumerable: true,
    get: function () {
      return _debug.printSprites;
    }
  });
  Object.defineProperty(exports, 'Motion', {
    enumerable: true,
    get: function () {
      return _motion.default;
    }
  });
  Object.defineProperty(exports, 'continueMotions', {
    enumerable: true,
    get: function () {
      return _motion.continueMotions;
    }
  });
  Object.defineProperty(exports, 'Tween', {
    enumerable: true,
    get: function () {
      return _tween.default;
    }
  });
});