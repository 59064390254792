define('ember-google-maps/utils/public-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  // Private properties
  var proxy = new WeakMap();

  /**
   * Create a public API object whose properties map to private properties on a
   * target object.
   *
   * The class constructor accepts two arguments: instance and schema.
   *
   * The instance is the object that will serve as the target or proxy for any
   * getters defined on the public API.
   *
   * The schema is an object that defines the structure of public API. The keys
   * define which properties will be exposed by the class, while the values are
   * strings that map to properties on the proxy.
   *
   * @class PublicAPI
   * @module ember-google-maps/utils/public-api
   * @namespace Utils
   */
  /* eslint-disable object-shorthand */

  var PublicAPI = function () {
    function PublicAPI(instance, schema) {
      var _this = this;

      _classCallCheck(this, PublicAPI);

      proxy.set(this, instance);

      instance.on('willDestroyElement', function () {
        proxy.set(_this, null);
      });

      this.defineProxyProperties(schema);
    }

    _createClass(PublicAPI, [{
      key: 'get',
      value: function get(prop) {
        var target = proxy.get(this);

        if (!target || target.isDestroyed || target.isDestroying) {
          throw Error('Cannot access property ' + prop + ' on the instance because it has been destroyed.');
        }

        if (typeof target[prop] === 'function') {
          return target[prop].bind(target);
        }

        return Ember.get(target, prop);
      }
    }, {
      key: 'defineProxyProperties',
      value: function defineProxyProperties(schema) {
        var _this2 = this;

        var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

        Object.keys(schema).forEach(function (prop) {
          var descriptor = { configurable: true };
          var pointer = schema[prop];

          if ((typeof pointer === 'undefined' ? 'undefined' : _typeof(pointer)) === 'object') {
            var nestedProp = _this2.actions ? _this2[prop] : {};
            descriptor['get'] = function () {
              return nestedProp;
            };

            _this2.defineProxyProperties(pointer, nestedProp);
          } else {
            var self = _this2;
            descriptor['get'] = function () {
              return self.get(pointer);
            };
          }

          Object.defineProperty(target, prop, descriptor);
        });
      }
    }, {
      key: 'reopen',
      value: function reopen(schema) {
        this.defineProxyProperties(schema);
        return this;
      }
    }]);

    return PublicAPI;
  }();

  exports.default = PublicAPI;
});