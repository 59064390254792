define('ember-animated/-private/concurrency-helpers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.rAF = rAF;
  exports.microwait = microwait;
  exports.wait = wait;
  exports.afterRender = afterRender;
  exports.allSettled = allSettled;
  var Promise = exports.Promise = void 0;
  if (window.Promise) {
    exports.Promise = Promise = window.Promise;
  } else {
    (true && Ember.warn("Unable to achieve proper rAF timing on this browser, microtask-based Promise implementation needed.", false, { id: "ember-animated-missing-microtask" }));

    exports.Promise = Promise = Ember.RSVP.Promise;
  }

  // This is a cancelable way to requestAnimationFrame that's designed
  // to resolve via the microtask queue (like real spec-compliant
  // Promises are supposed to). This lets us use rAF within
  // ember-concurrency correctly. RSVP promises within Ember do not
  // resolve on the microtask queue, because Ember schedules them inside
  // backburner.
  function rAF() {
    if (!nextFrame) {
      nextFrame = requestAnimationFrame(rAFDidFire);
    }
    var promise = new Promise(function (resolve) {
      nextFrameWaiters.push(resolve);
    });
    promise.__ec_cancel__ = removeWaiter.bind(promise);
    return promise;
  }

  function rAFDidFire(clock) {
    nextFrame = null;
    exports.currentFrameClock = currentFrameClock = clock;
    var waiters = nextFrameWaiters;
    nextFrameWaiters = [];
    for (var i = 0; i < waiters.length; i++) {
      waiters[i]();
    }
  }

  function removeWaiter() {
    var index = nextFrameWaiters.indexOf(this);
    if (index > -1) {
      nextFrameWaiters.splice(index, 1);
    }
  }

  var nextFrame = void 0;
  var nextFrameWaiters = [];

  // rAF guarantees that callbacks within the same frame will see the
  // same clock. We stash it here so that arbitrary code can easily ask
  // "did I already do that this frame?" without needing to thread the
  // clock values around.
  var currentFrameClock = exports.currentFrameClock = null;

  function microwait() {
    return new Promise(function (resolve) {
      return resolve();
    });
  }

  function wait() {
    var ms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    if (clock.now === originalClock) {
      var ticket = void 0;
      var promise = new Ember.RSVP.Promise(function (resolve) {
        ticket = setTimeout(resolve, ms);
      });
      promise.__ec_cancel__ = function () {
        clearTimeout(ticket);
      };
      return promise;
    } else {
      var canceled = false;
      var started = clock.now();
      var _promise = new Ember.RSVP.Promise(function (resolve) {
        function checkIt() {
          if (!canceled) {
            if (clock.now() - started > ms) {
              resolve();
            }
            rAF().then(checkIt);
          }
        }
        checkIt();
      });
      _promise.__ec_cancel__ = function () {
        canceled = true;
      };
      return _promise;
    }
  }

  function afterRender() {
    var ticket = void 0;
    var promise = new Promise(function (resolve) {
      ticket = Ember.run.schedule('afterRender', resolve);
    });
    promise.__ec_cancel__ = function () {
      Ember.run.cancel(ticket);
    };
    return promise;
  }

  // This provides a unified place to hook into time control for testing.
  var clock = exports.clock = {
    now: function now() {
      return new Date().getTime();
    }
  };

  var originalClock = clock.now;

  function allSettled(promises) {
    return Promise.all(promises.map(function (p) {
      if (p) {
        return p.catch(function () {
          return null;
        });
      }
    }));
  }
});