define('ember-animated/components/animated-if', ['exports', 'ember-animated/templates/components/animated-if'], function (exports, _animatedIf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _animatedIf.default,
    tagName: '',
    realGroup: Ember.computed('group', function () {
      return this.get('group') || 'animated_if_' + Math.floor(Math.random() * 1000000);
    })
  }).reopenClass({
    positionalParams: ['predicate']
  });
});