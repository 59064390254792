define('ember-animated/-private/ember-internals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.componentNodes = componentNodes;
  exports.keyForArray = keyForArray;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var getViewBounds = Ember.ViewUtils.getViewBounds;
  function componentNodes(view) {
    var bounds = getViewBounds(view);
    return {
      firstNode: bounds.firstNode,
      lastNode: bounds.lastNode
    };
  }

  function keyForArray(keyPath) {
    switch (keyPath) {
      case '@index':
        return index;
      case '@identity':
      case undefined:
      case null:
        return identity;
      default:
        return function (item) {
          return Ember.get(item, keyPath);
        };
    }
  }

  function index(item, index) {
    return String(index);
  }

  function identity(item) {
    switch (typeof item === 'undefined' ? 'undefined' : _typeof(item)) {
      case 'string':
      case 'number':
        return String(item);
      default:
        return Ember.guidFor(item);
    }
  }
});