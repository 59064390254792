define("ember-animated/easings/linear", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (t) {
    return t;
  };
});