define('ember-animated/components/animated-value', ['exports', 'ember-animated/templates/components/animated-value'], function (exports, _animatedValue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _animatedValue.default,
    items: Ember.computed('value', function () {
      return [this.get('value')];
    })
  }).reopenClass({
    positionalParams: ['value']
  });
});