define("ember-animated/easings/cosine", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.easeInAndOut = easeInAndOut;
  exports.easeIn = easeIn;
  exports.easeOut = easeOut;
  function easeInAndOut(t) {
    return 0.5 - Math.cos(t * Math.PI) / 2;
  }

  // if we naively switch from cosine to linear for the second half, we
  // would finish too soon. This is the adjustment factor that keeps us
  // within the 0 to 1 window.
  var adjust = 1 / 2 + 1 / Math.PI;

  var cutover = 1 / (2 * adjust);
  var b = (2 - Math.PI) / 4;
  var m = Math.PI / 2 * adjust;

  function easeIn(t) {
    if (t < cutover) {
      return easeInAndOut(t * adjust);
    } else {
      return m * t + b;
    }
  }

  function easeOut(t) {
    return 1 - easeIn(1 - t);
  }
});