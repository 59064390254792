define('ember-animated/-private/tween', ['exports', 'ember-animated/-private/concurrency-helpers', 'ember-animated/easings/cosine'], function (exports, _concurrencyHelpers, _cosine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var currentCurves = [];

  /*
    A Tween automatically recalculates on demand at most once per
    animation frame. As long as you're using the rAF helper from
    './concurrency-helpers', it will always be fresh. When many
    concurrent Tweens are running over the same duration at the same
    time, we can avoid a lot of duplicate work and keep them in sync.
  */

  var Tween = function () {
    function Tween(initialValue, finalValue, duration) {
      var easing = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _cosine.easeInAndOut;

      _classCallCheck(this, Tween);

      if (typeof easing !== 'function') {
        throw new Error("Tried to make a Tween with an invalid easing function");
      }
      this.curve = MotionCurve.findOrCreate(duration, easing);
      this.initialValue = initialValue;
      this.finalValue = finalValue;
      this.diff = finalValue - initialValue;
    }

    _createClass(Tween, [{
      key: 'plus',
      value: function plus(otherTween) {
        return new DerivedTween([this, otherTween], function (a, b) {
          return a.currentValue + b.currentValue;
        });
      }
    }, {
      key: 'currentValue',
      get: function get() {
        return this.initialValue + this.diff * this.curve.spaceProgress;
      }
    }, {
      key: 'done',
      get: function get() {
        return this.curve.done;
      }
    }]);

    return Tween;
  }();

  exports.default = Tween;

  var DerivedTween = function () {
    function DerivedTween(inputs, combinator) {
      _classCallCheck(this, DerivedTween);

      this._finalValue = null;
      this.inputs = inputs.map(function (t) {
        if (t.done) {
          // If one of our inputs has already finished, we can just keep
          // its final value around and drop the reference to the actual
          // Tween. This prevents long chains of derived tweens from
          // growing without bound during continuous animations.
          return { currentValue: t.currentValue, done: true, finalValue: t.finalValue };
        } else {
          return t;
        }
      });
      this.combinator = combinator;
    }

    _createClass(DerivedTween, [{
      key: 'finalValue',
      get: function get() {
        if (this._finalValue == null) {
          var accum = 0;
          for (var i = 0; i < this.inputs.length; i++) {
            accum += this.inputs[i].finalValue;
          }
          this._finalValue = accum;
        }
        return this._finalValue;
      }
    }, {
      key: 'currentValue',
      get: function get() {
        return this.combinator.apply(this, _toConsumableArray(this.inputs));
      }
    }, {
      key: 'done',
      get: function get() {
        return !this.inputs.find(function (t) {
          return !t.done;
        });
      }
    }]);

    return DerivedTween;
  }();

  var MotionCurve = function () {
    _createClass(MotionCurve, null, [{
      key: 'findOrCreate',
      value: function findOrCreate(duration, easing) {
        var shared = currentCurves.find(function (c) {
          return c.duration === duration && c.easing === easing;
        });
        if (shared) {
          return shared;
        }
        var created = new this(duration, easing);
        currentCurves.push(created);
        (0, _concurrencyHelpers.rAF)().then(function () {
          currentCurves.splice(currentCurves.indexOf(created), 1);
        });
        return created;
      }
    }]);

    function MotionCurve(duration, easing) {
      _classCallCheck(this, MotionCurve);

      this.startTime = _concurrencyHelpers.clock.now();
      this.duration = duration;
      this.easing = easing;
      this._doneFrames = 0;
      this._tick();
    }

    _createClass(MotionCurve, [{
      key: '_tick',
      value: function _tick() {
        if (this._lastTick !== _concurrencyHelpers.currentFrameClock) {
          this._lastTick = _concurrencyHelpers.currentFrameClock;
          this._runTime = _concurrencyHelpers.clock.now() - this.startTime;
          this._timeProgress = this.duration === 0 ? 1 : Math.min(this._runTime / this.duration, 1);
          this._spaceProgress = Math.min(this.easing(this._timeProgress), 1);
          if (this._timeProgress >= 1) {
            this._doneFrames++;
          }
        }
      }
    }, {
      key: 'runTime',
      get: function get() {
        this._tick();
        return this._runTime;
      }
    }, {
      key: 'timeProgress',
      get: function get() {
        this._tick();
        return this._timeProgress;
      }
    }, {
      key: 'spaceProgress',
      get: function get() {
        this._tick();
        return this._spaceProgress;
      }
    }, {
      key: 'done',
      get: function get() {
        this._tick();
        return this._doneFrames > 1;
      }
    }]);

    return MotionCurve;
  }();
});