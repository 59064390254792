define('ember-animated/-private/scheduler', ['exports', 'ember-animated'], function (exports, _emberAnimated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.spawn = spawn;
  exports.spawnChild = spawnChild;
  exports.stop = stop;
  exports.logErrors = logErrors;
  exports.current = current;
  exports.childrenSettled = childrenSettled;
  exports.parallel = parallel;
  exports.serial = serial;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function spawn(genFn) {
    var m = new MicroRoutine(genFn, false);
    return m.promise;
  }

  function spawnChild(genFn) {
    var m = new MicroRoutine(genFn, true);
    return m.promise;
  }

  function stop(microRoutinePromise) {
    if (microRoutinePromise === current()) {
      var e = new Error('TaskCancelation');
      e.message = 'TaskCancelation';
      throw e;
    }
    var microRoutine = microRoutines.get(microRoutinePromise);
    if (microRoutine) {
      microRoutine.stop();
    }
  }

  function logErrors(fn) {
    ensureCurrent('logErrors').errorLogger = fn;
  }

  function current() {
    var current = getCurrent();
    if (current) {
      return current.promise;
    }
  }

  function childrenSettled() {
    return _emberAnimated.Promise.all(ensureCurrent('childrenSettled').linked.map(function (child) {
      return child.promise.catch(function () {
        return null;
      });
    }));
  }

  var withCurrent = void 0,
      getCurrent = void 0,
      onStack = void 0;
  {
    var _current = void 0;
    var prior = [];
    withCurrent = function withCurrent(routine, fn) {
      prior.unshift({ microroutine: _current });
      _current = routine;
      try {
        return fn();
      } finally {
        var restore = prior.shift();
        _current = restore.microroutine;
        if (restore.throw) {
          /*
             Why is this not really "unsafe"? Because if the
             microroutine that we are restoring has been cancelled, the
             cancellation takes precedence over any exception that it
             was going to see, so it's OK that this throw will silently
             stomp a throw coming out of the above block.
          */
          throw restore.throw; // eslint-disable-line no-unsafe-finally
        }
      }
    };
    getCurrent = function getCurrent() {
      return _current;
    };
    onStack = function onStack(microroutine) {
      return prior.find(function (entry) {
        return entry.microroutine === microroutine;
      });
    };
  }

  function ensureCurrent(label) {
    var current = getCurrent();
    if (!current) {
      throw new Error(label + ': only works inside a running microroutine');
    }
    return current;
  }

  var loggedErrors = new WeakMap();
  var microRoutines = new WeakMap();

  var MicroRoutine = function () {
    function MicroRoutine(genFn, linked) {
      var _this = this;

      _classCallCheck(this, MicroRoutine);

      this.linked = [];
      this.generator = genFn();
      this.promise = new _emberAnimated.Promise(function (res, rej) {
        _this.resolve = res;
        _this.reject = rej;
      });
      microRoutines.set(this.promise, this);
      this.promise.__ec_cancel__ = this.stop.bind(this);
      this.stopped = false;
      if (linked) {
        var parent = ensureCurrent('spawnChild');
        parent.linked.push(this);
        this.errorLogger = parent.errorLogger;
      } else {
        this.errorLogger = null;
      }
      this.wake('fulfilled', undefined);
    }

    _createClass(MicroRoutine, [{
      key: 'wake',
      value: function wake(state, value) {
        var _this2 = this;

        if (this.stopped) {
          return;
        }
        withCurrent(this, function () {
          try {
            if (state === 'fulfilled') {
              _this2.state = _this2.generator.next(value);
            } else {
              _this2.state = _this2.generator.throw(value);
            }
            if (_this2.state.done) {
              _this2.resolve(_this2.state.value);
            } else {
              _emberAnimated.Promise.resolve(_this2.state.value).then(function (value) {
                return _this2.wake('fulfilled', value);
              }, function (reason) {
                return _this2.wake('rejected', reason);
              });
            }
          } catch (err) {
            _this2.state = {
              done: true
            };
            _this2.linked.forEach(function (microRoutine) {
              microRoutine.stop();
            });
            if (err.message !== 'TaskCancelation') {
              _this2.reject(err);
              if (_this2.errorLogger) {
                if (!loggedErrors.get(err)) {
                  loggedErrors.set(err, true);
                  _this2.errorLogger.call(null, err);
                }
              }
            }
          }
        });
      }
    }, {
      key: 'stop',
      value: function stop() {
        var _this3 = this;

        this.stopped = true;
        if (this.state && isPromise(this.state.value) && typeof this.state.value.__ec_cancel__ === 'function') {
          this.state.value.__ec_cancel__();
        }
        this.linked.forEach(function (microRoutine) {
          microRoutine.stop();
        });
        var e = new Error('TaskCancelation');
        e.message = 'TaskCancelation';
        if (getCurrent() === this) {
          // when a microroutine calls stop() resulting it stopping
          // itself, the stop call throws TaskCancellation to unwind its
          // own stack.
          throw e;
        }
        var s = onStack(this);
        if (s) {
          // because of the synchronous nature of spawn() and stop(), it's
          // possible that the microroutine we're stopping is already on
          // the current call stack above us. If we tried to
          // cancelGenerator it would give a "generator already running"
          // exception. Instead we save the exception to throw when
          // control returns back to the stopped microroutine.
          s.throw = e;
        } else {
          // the stopped microroutine is not on our call stack, so we can
          // throw an exception into it to unwind the generator's stack.
          withCurrent(this, function () {
            return cancelGenerator(_this3.generator);
          });
        }
      }
    }]);

    return MicroRoutine;
  }();

  function cancelGenerator(generator) {
    var e = new Error('TaskCancelation');
    e.message = 'TaskCancelation';
    try {
      generator.throw(e);
    } catch (err) {
      if (err.message !== 'TaskCancelation') {
        throw err;
      }
    }
  }

  function isPromise(thing) {
    return thing && typeof thing.then === 'function';
  }

  // composes several promise-returning functions into a single
  // promise-returning function that executes all in parallel.
  //
  // This allows point-free style, like:
  //   sprites.forEach(parallel(move, scale)).
  //
  function parallel() {
    for (var _len = arguments.length, functions = Array(_len), _key = 0; _key < _len; _key++) {
      functions[_key] = arguments[_key];
    }

    return function () {
      var _this4 = this;

      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return _emberAnimated.Promise.all(functions.map(function (f) {
        return f.apply(_this4, args);
      }));
    };
  }

  // composes several promise-returning functions into a single
  // promise-returning function that executes all in series.
  //
  // This allows point-free style, like:
  //   sprites.forEach(serial(scale, move)).
  //
  function serial() {
    for (var _len3 = arguments.length, functions = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      functions[_key3] = arguments[_key3];
    }

    return function () {
      for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      return spawnChild(regeneratorRuntime.mark(function _callee() {
        var _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, fn;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _iteratorNormalCompletion = true;
                _didIteratorError = false;
                _iteratorError = undefined;
                _context.prev = 3;
                _iterator = functions[Symbol.iterator]();

              case 5:
                if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                  _context.next = 12;
                  break;
                }

                fn = _step.value;
                _context.next = 9;
                return fn.apply(this, args);

              case 9:
                _iteratorNormalCompletion = true;
                _context.next = 5;
                break;

              case 12:
                _context.next = 18;
                break;

              case 14:
                _context.prev = 14;
                _context.t0 = _context['catch'](3);
                _didIteratorError = true;
                _iteratorError = _context.t0;

              case 18:
                _context.prev = 18;
                _context.prev = 19;

                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }

              case 21:
                _context.prev = 21;

                if (!_didIteratorError) {
                  _context.next = 24;
                  break;
                }

                throw _iteratorError;

              case 24:
                return _context.finish(21);

              case 25:
                return _context.finish(18);

              case 26:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[3, 14, 18, 26], [19,, 21, 25]]);
      }));
    };
  }
});