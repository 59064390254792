define('ember-animated/-private/motion', ['exports', 'ember-animated/-private/scheduler', 'ember-animated'], function (exports, _scheduler, _emberAnimated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.continueMotions = continueMotions;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var motions = new WeakMap();
  var bridges = new WeakMap();

  var Motion = function () {
    function Motion(sprite) {
      var _this = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      _classCallCheck(this, Motion);

      this.sprite = sprite;
      this.opts = opts;

      // You can set this property directly if you want to. If you leave
      // it null the transition will apply its own overall duration,
      // which is often what you want.
      this.duration = null;
      if (opts.duration != null) {
        this.duration = opts.duration;
      }

      this._setupMotionList();
      this._promise = new _emberAnimated.Promise(function (resolve, reject) {
        _this._resolve = resolve;
        _this._reject = reject;
      });
    }

    _createClass(Motion, [{
      key: 'run',
      value: function run() {
        var context = this.sprite._transitionContext;
        if (this.duration == null) {
          this.duration = context.duration;
        }
        var self = this;
        return (0, _scheduler.spawnChild)(regeneratorRuntime.mark(function _callee() {
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  context.onMotionStart(self.sprite);
                  _context.prev = 1;
                  return _context.delegateYield(self._run(), 't0', 3);

                case 3:
                  _context.prev = 3;

                  context.onMotionEnd(self.sprite);
                  return _context.finish(3);

                case 6:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[1,, 3, 6]]);
        }));
      }
    }, {
      key: 'interrupted',
      value: function interrupted() /* motions */{}
    }, {
      key: 'animate',
      value: regeneratorRuntime.mark(function animate() {
        return regeneratorRuntime.wrap(function animate$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
              case 'end':
                return _context2.stop();
            }
          }
        }, animate, this);
      })
    }, {
      key: '_run',
      value: regeneratorRuntime.mark(function _run() {
        var _this2 = this;

        var others;
        return regeneratorRuntime.wrap(function _run$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.prev = 0;
                others = this._motionList.filter(function (m) {
                  return m !== _this2;
                });

                if (this._inheritedMotionList) {
                  others = others.concat(this._inheritedMotionList);
                }
                if (others.length > 0) {
                  this.interrupted(others);
                }
                return _context3.delegateYield(this.animate(), 't0', 5);

              case 5:
                this._resolve();
                _context3.next = 12;
                break;

              case 8:
                _context3.prev = 8;
                _context3.t1 = _context3['catch'](0);

                if (_context3.t1.message !== 'TaskCancelation') {
                  this._reject(_context3.t1);
                }
                throw _context3.t1;

              case 12:
                _context3.prev = 12;

                (0, _emberAnimated.rAF)().then(function () {
                  return _this2._clearMotionList();
                });
                return _context3.finish(12);

              case 15:
              case 'end':
                return _context3.stop();
            }
          }
        }, _run, this, [[0, 8, 12, 15]]);
      })
    }, {
      key: '_setupMotionList',
      value: function _setupMotionList() {
        var _this3 = this;

        var element = this.sprite.element;
        var motionList = motions.get(element);
        if (!motionList) {
          motions.set(element, motionList = []);
        }
        this._motionList = motionList;
        // we wait here so that if multiple motions are started
        // simultaneously, the latter ones don't see the earlier ones as
        // interrupted.
        (0, _emberAnimated.microwait)().then(function () {
          if (_this3._motionList) {
            _this3._motionList.unshift(_this3);
          }
        });
        var bridge = bridges.get(element);
        if (bridge) {
          var inheritedMotions = motions.get(bridge);
          if (inheritedMotions) {
            this._inheritedMotionList = inheritedMotions;
          }
        }
      }
    }, {
      key: '_clearMotionList',
      value: function _clearMotionList() {
        var index = this._motionList.indexOf(this);
        this._motionList.splice(index, 1);
        if (this._motionList.length === 0) {
          motions.delete(this.sprite.element);
        }
        this._motionList = null;
      }
    }]);

    return Motion;
  }();

  exports.default = Motion;
  function continueMotions(oldElement, newElement) {
    bridges.set(newElement, oldElement);
    (0, _emberAnimated.rAF)().then(function () {
      if (bridges.get(newElement) === oldElement) {
        bridges.delete(newElement);
      }
    });
  }
});