define('ember-animated/-private/margin-collapse', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.collapsedChildren = collapsedChildren;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // takes an element, the element's computed style, and either 'Top' or
  // 'Bottom'.
  //
  // returns list of Element for increasingly deep descendants
  // of the given element whose top (or bottom) margin collapses with
  // the given element's.
  function collapsedChildren(element, cs, which) {
    var children = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

    // margin collapse only happens if we have no border or padding
    if (isBlock(cs) && cs['border' + which + 'Width'] === '0px' && cs['padding' + which] === '0px') {
      var block = void 0;
      if (which === 'Top') {
        block = firstChildBlock(element);
      } else {
        block = lastChildBlock(element);
      }
      if (block) {
        var _block = block,
            _block2 = _slicedToArray(_block, 2),
            child = _block2[0],
            childCS = _block2[1];

        children.push(child);
        collapsedChildren(child, childCS, which, children);
      }
    }
    return children;
  }

  function firstChildBlock(element) {
    for (var i = 0; i < element.children.length; i++) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);
      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }
      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }
  }

  function lastChildBlock(element) {
    for (var i = element.children.length - 1; i >= 0; i--) {
      var child = element.children[i];
      var childCS = getComputedStyle(child);
      if (childCS.clear !== 'none') {
        // an intervening block with clearance prevents margin collapse
        return;
      }
      if (isBlock(childCS)) {
        return [child, childCS];
      }
    }
  }

  function isBlock(cs) {
    return cs.display === 'block' && (cs.position === 'static' || cs.position === 'relative') && cs.float === 'none' && cs.overflow === 'visible';
  }
});