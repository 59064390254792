define('ember-animated/-private/debug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var printSprites = void 0;

  if (true) {
    exports.printSprites = printSprites = function printSprites(context, label) {
      var isOrphan = void 0;
      var prefix = label ? label + ' ' : '';
      /* eslint no-console:0 */
      var spriteSummary = ['inserted', 'kept', 'removed', 'sent', 'received'].map(function (type) {
        return type + '=' + context['_' + type + 'Sprites'].map(function (s) {
          if (isOrphan == null) {
            // the first time we encounter a sprite, we use it to get a
            // handle on whether this whole transition is happening
            // inside animated-orphans or not. In the case of an orphan,
            // we may have no parentElement (because our original home
            // was destroyed already) or we may have the
            // animated-orphans component as our parent (because we were
            // already an orphan and got interrupted so we're starting a
            // new motion)
            isOrphan = !s.element.parentElement || s.element.parentElement.classList.contains('animated-orphans');
          }
          return s.owner.id;
        }).join(',');
      }).join(" | ");
      console.log(prefix + spriteSummary + (isOrphan ? ' | (orphan)' : ''));
    };
  } else {
    exports.printSprites = printSprites = function printSprites() {};
  }

  exports.printSprites = printSprites;
});